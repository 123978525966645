import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 4 hrs. `}<meta itemProp="cookTime" content="PT4H" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Beef brisket, thawed</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Paprika, ~2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic powder, ~2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne, ~1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, ~1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black pepper, ~1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Onions, 1-2 sliced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">BBQ sauce, 1/2-1 cup</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Dutch oven</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Tongs</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Thaw beef overnight</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Slice onions</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Dry brisket with a paper towel and rub with paprika, garlic powder, cayenne, salt, and pepper. Let sit for at least 30 min.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Heat Dutch oven until hot, add butter.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Once butter is melted, sear beef, about 2-5 min. per side until brown and crusty. Remove from pan, set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook onions in the same pan until carmelized.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Layer onions evenly in the pan, return brisket on top of onions.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pour BBQ sauce over brisket until just covered.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Roast 1 hr. 15 min. per pound of brisket at 300F.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Halfway through, pour another layer of BBQ sauce.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Remove from oven, let rest at least 30 min.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      